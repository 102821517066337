<template>
  <div class="modal-ticket-cancel">
    <esmp-modal
      v-model="show"
      :title="`Отмена ${cancelText}`"
      :width="800"
      class-name="modal-ticket-cancel"
    >
      <hm-text-area
        :placeholder="`Причина отмены ${cancelText}`"
        v-model="comment"
      />
      <template #footer>
        <esmp-button @click="okHandler">
          Да, отменить {{ cancelBtnText }}
        </esmp-button>
        <esmp-button @click="closeModal">
          Нет, я передумал
        </esmp-button>
      </template>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import HmTextArea from '@/components/hm-form/controls/HmTextArea.vue';
import { BUSINESS_TRIP_STATE } from '@/constants/businessTrip';

export default {
  name: 'ModalTicketCancel',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    sourceSystem: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'ticket',
      validator(value) {
        return ['ticket', 'approval-request'].includes(value);
      },
    },
    ticketId: {
      type: [String, Number],
      default: undefined,
    },
  },
  components: {
    HmTextArea,
  },
  data() {
    return {
      comment: '',
    };
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
    isTicket() {
      return this.type === 'ticket';
    },
    cancelText() {
      return this.isTicket ? 'заявки' : 'согласования';
    },
    cancelBtnText() {
      return this.isTicket ? 'заявку' : 'согласование';
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    okHandler() {
      if (this.isTicket) {
        return this.cancelHandler();
      }
      return this.cancelApprovalRequestHandler();
    },
    async cancelHandler() {
      if (this.comment) {
        this.setLoading({ key: 'app', value: true });

        await this.$API.ticket.cancelTicket(
          this.ticketId,
          { comment: this.comment },
          { source: this.sourceSystem },
        )
          .then(() => {
            this.$EsmpNotify.$show('Заявка отменена', 'success');
            this.closeModal();
            this.$emit('fetch-tickets');
          })
          .catch((err) => err);
        this.setLoading({ key: 'app', value: false });
      } else {
        this.$EsmpNotify.$show('Введите комментарий', 'error');
      }
    },
    async cancelApprovalRequestHandler() {
      if (this.comment) {
        this.setLoading({ key: 'app', value: true });

        await this.$API.ticket.cancelRequestApproval(
          this.ticketId,
          {
            comment: this.comment,
          },
          { source: this.sourceSystem },
        )
          .then(() => {
            this.closeModal();
            this.$EsmpNotify.$show('Согласование отменено', 'success');

            this.$emit('set-status', `${BUSINESS_TRIP_STATE.editable}`);
          })
          .catch((err) => err)
          .finally(() => {
            this.setLoading({ key: 'app', value: false });
          });
      } else {
        this.$EsmpNotify.$show('Введите комментарий', 'error');
      }
    },
    closeModal() {
      this.comment = '';
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.esmp-modal-body {
  font-size: 16px;
}
</style>
